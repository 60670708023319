.show,
.show-skeleton {
  height: 192px;
  width: 342px;
  margin: 20px 10px;
}

.show-skeleton,
.show-poster-skeleton {
  flex-shrink: 0;
  border-radius: 15px;
}

.show,
.show-poster {
  --border-radius: 15px;
  --ripple-color: transparent;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-shrink: 0;
  position: relative;
}

.show-poster,
.show-poster-skeleton {
  width: 154px;
  height: 231px;
  margin: 20px 10px;
}

.play {
  font-size: 64px;
  position: relative;
  bottom: 15px;
}

.showDetails {
  padding: 10px;
  position: absolute;
  left: -15px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.showDetails h1,
.showDetails p {
  padding: 0px;
  margin: 0px;
}

.modal {
  --height: 50vh;
}

.show-page header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 400px;
  width: 100%;
  background-position: center;
  background-size: cover;
}

.check {
  position: absolute;
  left: 0;
  top: 10px;
}
