.horiz::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
}

.horiz::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  background-color: transparent;
}

.horiz::-webkit-scrollbar-thumb {
  background-color: white;
}

.horiz {
  width: 100%;
  overflow-x: scroll;
  display: flex;
}
